export const dashboardRoute = {
  dashboardHome: {
    path: "home",
    name: "Home",
  },
  topup: {
    path: "topup",
    name: "Topup",
  },
  komisiStore: {
    path: "komisi",
    name: "Komisi Master Hub",
  },
  areaManagement: {
    path: "area-management",
    name: "Area Management",
  },
  hoManagement: {
    path: "ho-management",
    name: "Ho Management",
  },
  userManagement: {
    path: "user-management",
    name: "User Management",
  },
  vendorManagement: {
    path: "vendor-management",
    name: "Vendor Management",
  },
  detailTransaction: {
    path: "detail-transaction",
    name: "Detail Transaction",
  },
  recapTransaction: {
    path: "recap-transaction",
    name: "Recap Transaction",
  },
  shipmentStatus: {
    path: "shipment-status",
    name: "Shipment Status",
  },
  wallet: {
    path: "wallet",
    name: "Wallet",
  },
  createOrder: {
    path: "create-order",
    name: "Create Order",
  },
  fareCheck: {
    path: "cek-tarif",
    name: "Cek Tarif",
  },
  jobOrder: {
    path: "job-order",
    name: "Job Order",
  },
  managePickup: {
    path: "manage-pickup",
    name: "Manage Pickup",
  },
  orderConfirmation: {
    path: "order-confirmation",
    name: "Order Confirmation",
  },
  orderManagement: {
    path: "order-management",
    name: "Order Management",
  },
  invoiceManagement: {
    path: "invoice-management",
    name: "Invoice Management",
  },
  bagging: {
    path: "bagging",
    name: "Bagging",
  },
  incoming: {
    path: "incoming",
    name: "Incoming",
  },
  manageManifest: {
    path: "manage-manifest",
    name: "Manage Manifest",
  },
  manageOutbound: {
    path: "manage-outbound",
    name: "Manage Outbound",
  },
  packingList: {
    path: "packing-list",
    name: "Packing List",
  },
  unbagging: {
    path: "unbagging",
    name: "Unbagging",
  },
  deliveryRunSheet: {
    path: "delivery-run-sheet",
    name: "Delivery Run Sheet",
  },
  irregularity: {
    path: "irregularity",
    name: "Irregularity",
  },
  manageDelivery: {
    path: "manage-delivery",
    name: "Manage Delivery",
  },
  outgoing: {
    path: "outgiong",
    name: "Outgoing",
  },
  tracking: {
    path: "tracking",
    name: "Tracking",
  },
  profile: {
    path: "profile",
    name: "Profile",
  },
  financialReport: {
    path: "financial-report",
    name: "Financial Report",
  },
  fareCheckInternational: {
    path: "cek-tarif-international",
    name: "Cek Tarif International",
  },
  globalVariable: {
    path: "globalVariable",
    name: "global variable",
  },
  logs: {
    path: "logs",
    name: "Logs",
  },
};
