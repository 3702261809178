import Vue from "vue";
import VueRouter from "vue-router";
import { dashboardRoute } from "../constant/routeName";

// import Home from "../views/Home.vue";

// import Dashboard from "../views/dashboard/Dashboard.vue";
// import DashboardHome from "../views/dashboard/DashboardHome.vue";

// settings
// import AreaManagement from "../views/dashboard/settings/AreaManagement.vue";
// import HubManagement from "../views/dashboard/settings/HubManagement.vue";
// import UserManagement from "../views/dashboard/settings/UserManagement.vue";
// import VendorManagement from "../views/dashboard/settings/VendorManagement.vue";
// import Profile from "../views/dashboard/settings/Profile.vue";

// module
// import DetailTransaction from "../views/dashboard/module/DetailTransaction.vue";
// import RecapTransaction from "../views/dashboard/module/RecapTransaction.vue";
// import ShipmentStatus from "../views/dashboard/module/ShipmentStatus.vue";
// import Wallet from "../views/dashboard/module/Wallet.vue";

// inbound
// import CreateOrder from "../views/dashboard/inbound/CreateOrder.vue";
// import FareCheck from "../views/dashboard/inbound/FareCheck.vue";
// import JobOrder from "../views/dashboard/inbound/JobOrder.vue";
// import ManagePickup from "../views/dashboard/inbound/ManagePickup.vue";
// import OrderConfirmation from "../views/dashboard/inbound/OrderConfirmation.vue";
// import OrderManagement from "../views/dashboard/inbound/OrderManagement.vue";
// import InvoiceManagement from "../views/dashboard/inbound/InvoiceManagement.vue";

// operation
// import Bagging from "../views/dashboard/operation/Bagging.vue";
// import Incoming from "../views/dashboard/operation/Incoming.vue";
// import ManageManifest from "../views/dashboard/operation/ManageManifest.vue";
// import ManageOutbound from "../views/dashboard/operation/ManageOutbound.vue";
// import PackingList from "../views/dashboard/operation/PackingList.vue";
// import Unbagging from "../views/dashboard/operation/Unbagging.vue";

// delivery
// import DeliveryRunSheet from "../views/dashboard/delivery/DeliveryRunSheet.vue";
// import Irregularity from "../views/dashboard/delivery/Irregularity.vue";
// import ManageDelivery from "../views/dashboard/delivery/ManageDelivery.vue";
// import Outgoing from "../views/dashboard/delivery/Outgoing.vue";
// import Tracking from "../views/dashboard/delivery/Tracking.vue";

// import PageNotFound from "../views/PageNotFound.vue";

import Document from "../views/document/Document.vue";
// import ShippingLabel from "../views/document/ShippingLabel.vue";
// import Receipt from "../views/document/Receipt.vue";
import { getTokenCookie } from "../utils/cookies";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PageNotFound.vue"),
  },
  {
    path: "/document",
    name: "document",
    component: Document,
    children: [
      {
        path: "label",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/document/ShippingLabel.vue"
          ),
      },
      {
        path: "receipt",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/document/Receipt.vue"
          ),
      },
      {
        path: "manifest",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/document/Manifest.vue"
          ),
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/dashboard/Dashboard.vue"
      ),
    children: [
      {
        path: dashboardRoute.dashboardHome.path,
        name: dashboardRoute.dashboardHome.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/DashboardHome.vue"
          ),
      },
      {
        path: dashboardRoute.komisiStore.path,
        name: dashboardRoute.komisiStore.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/module/KomisiStore.vue"
          ),
      },
      {
        path: dashboardRoute.topup.path,
        name: dashboardRoute.topup.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/module/Wallet.vue"
          ),
      },
      {
        path: dashboardRoute.areaManagement.path,
        name: dashboardRoute.areaManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/AreaManagement.vue"
          ),
      },
      {
        path: dashboardRoute.userManagement.path,
        name: dashboardRoute.userManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/UserManagement.vue"
          ),
      },
      {
        path: dashboardRoute.hoManagement.path,
        name: dashboardRoute.hoManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/HoManagement.vue"
          ),
      },
      {
        path: dashboardRoute.vendorManagement.path,
        name: dashboardRoute.vendorManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/VendorManagement.vue"
          ),
      },
      {
        path: dashboardRoute.profile.path,
        name: dashboardRoute.profile.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/Profile.vue"
          ),
      },
      {
        path: dashboardRoute.globalVariable.path,
        name: dashboardRoute.globalVariable.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/GlobalVar.vue"
          ),
      },
      {
        path: dashboardRoute.logs.path,
        name: dashboardRoute.logs.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/settings/Logs.vue"
          ),
      },
      {
        path: dashboardRoute.shipmentStatus.path,
        name: dashboardRoute.shipmentStatus.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/module/ShipmentStatus.vue"
          ),
      },
      {
        path: dashboardRoute.detailTransaction.path,
        name: dashboardRoute.detailTransaction.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/module/DetailTransaction.vue"
          ),
      },
      {
        path: dashboardRoute.recapTransaction.path,
        name: dashboardRoute.recapTransaction.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/module/RecapTransaction.vue"
          ),
      },
      {
        path: dashboardRoute.wallet.path,
        name: dashboardRoute.wallet.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/module/Wallet.vue"
          ),
      },
      {
        path: dashboardRoute.financialReport.path,
        name: dashboardRoute.financialReport.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/report/FinancialReport.vue"
          ),
      },
      {
        path: dashboardRoute.fareCheck.path,
        name: dashboardRoute.fareCheck.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/FareCheck.vue"
          ),
      },
      {
        path: dashboardRoute.fareCheckInternational.path,
        name: dashboardRoute.fareCheckInternational.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/FareCheckInternational.vue"
          ),
      },
      {
        path: dashboardRoute.jobOrder.path,
        name: dashboardRoute.jobOrder.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/JobOrder.vue"
          ),
      },
      {
        path: dashboardRoute.managePickup.path,
        name: dashboardRoute.managePickup.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/ManagePickup.vue"
          ),
      },
      {
        path: dashboardRoute.orderConfirmation.path,
        name: dashboardRoute.orderConfirmation.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/OrderConfirmation.vue"
          ),
      },
      {
        path: dashboardRoute.orderManagement.path,
        name: dashboardRoute.orderManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/OrderManagement.vue"
          ),
      },
      {
        path: dashboardRoute.invoiceManagement.path,
        name: dashboardRoute.invoiceManagement.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/inbound/InvoiceManagement.vue"
          ),
      },
      {
        path: dashboardRoute.bagging.path,
        name: dashboardRoute.bagging.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/operation/Bagging.vue"
          ),
      },
      {
        path: dashboardRoute.incoming.path,
        name: dashboardRoute.incoming.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/operation/Incoming.vue"
          ),
      },
      {
        path: dashboardRoute.manageManifest.path,
        name: dashboardRoute.manageManifest.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/operation/ManageManifest.vue"
          ),
      },
      {
        path: dashboardRoute.manageOutbound.path,
        name: dashboardRoute.manageOutbound.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/operation/ManageOutbound.vue"
          ),
      },
      {
        path: dashboardRoute.packingList.path,
        name: dashboardRoute.packingList.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/operation/PackingList.vue"
          ),
      },
      {
        path: dashboardRoute.unbagging.path,
        name: dashboardRoute.unbagging.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/operation/Unbagging.vue"
          ),
      },
      {
        path: dashboardRoute.deliveryRunSheet.path,
        name: dashboardRoute.deliveryRunSheet.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/delivery/DeliveryRunSheet.vue"
          ),
      },
      {
        path: dashboardRoute.irregularity.path,
        name: dashboardRoute.irregularity.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/delivery/Irregularity.vue"
          ),
      },
      {
        path: dashboardRoute.manageDelivery.path,
        name: dashboardRoute.manageDelivery.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/delivery/ManageDelivery.vue"
          ),
      },
      {
        path: dashboardRoute.outgoing.path,
        name: dashboardRoute.outgoing.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/delivery/Outgoing.vue"
          ),
      },
      {
        path: dashboardRoute.tracking.path,
        name: dashboardRoute.tracking.name,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/dashboard/delivery/Tracking.vue"
          ),
      },
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getTokenCookie();

  if (to.fullPath !== "/" && !isAuthenticated) {
    next("/");
  }
  if (to.fullPath === "/" && isAuthenticated) {
    next(dashboardRoute.dashboardHome);
  }
  next();
});

export default router;
