const env = process.env.NODE_ENV;

let baseUrl = "";
let jneUrl = "";
let urlVA = "";
let trackingJNE = "";
let trackingInternational = "";

switch (env) {
  case "development":
    // baseUrl = "https://api-dev.trolly-app.com";
    baseUrl = "http://localhost:2000";
    urlVA = "http://localhost:4000/v1";

    jneUrl = "http://apiv2.jne.co.id:10102";
    trackingJNE = "https://serene-coast-07936.herokuapp.com/api/tracking";
    trackingInternational = "https://tracking-trolly.herokuapp.com/";

    break;
  case "production":
    baseUrl = "https://api.trolly-app.com";
    urlVA = "http://localhost:4000/v1";

    jneUrl = "http://apiv2.jne.co.id:10101";
    trackingJNE = "https://serene-coast-07936.herokuapp.com/api/tracking";
    trackingInternational = "https://tracking-trolly.herokuapp.com/";

    break;
}

export { baseUrl, jneUrl, trackingJNE, trackingInternational, urlVA };
